import Vue from 'vue';

const EventBus = new Vue();

export default EventBus;

export const CHANGE_LANG = 'change-lang';
export const CHANGE_IDENTITY = 'change-identity';

export const CLICK_PLAY_NOW = 'click-play-now';
export const CLICK_LOGIN = 'click-login';
export const CLICK_LOGOUT = 'click-logout';
export const CLICK_HOME = 'click-home';
export const CLICK_MENU = 'click-menu';

export const TOGGLE_BURGER = 'toggle-burger';
export const TOGGLE_LANG = 'toggle-lang';

export const HIDE_BURGER = 'hide-burger';
export const HIDE_LANG = 'hide-lang';
