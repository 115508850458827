<template>
  <a :class="getClasses" :href="bullet.to" :target="target" @click="invokeLink">
    {{ bullet.label }}
  </a>
</template>

<script>
import EventBus, { HIDE_BURGER } from '../../EventBus';
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    bullet: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getClasses() {
      let c = this.classes;
      if (this.active) {
        c = c + ' active';
      }
      return c;
    },
    target() {
      if (this.bullet.target) {
        return this.bullet.target;
      }

      return '_self';
    },
  },
  methods: {
    invokeLink() {
      EventBus.$emit(HIDE_BURGER);
    },
  },
};
</script>
