<template>
  <div class="container webig-bar d-none d-lg-block">
    <a
      v-for="bullet in bullets"
      :key="bullet.title"
      :href="'https://ryapp.vendale.net/app' + bullet.url"
    >
      <img
        :src="'https://app.ryzom.com' + bullet.image"
        :alt="bullet.title"
        :title="bullet.title"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    bullets: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
