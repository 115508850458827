<template>
  <div style="width: 275px;">
    <div style="position: absolute;z-index: 9999;" class="pt-1">
      <nuxt-link v-if="useNuxtLinks" :to="homeLocation" @click="clickHomeLink"
        ><img
          :src="config.ciPath + '/assets/images/ryzom_logo.png'"
          style="height: 70px;"
          alt="Ryzom"
          title="Ryzom"
      /></nuxt-link>
      <a v-else-if="useNativeLinks" :href="homeLocation" @click="clickHomeLink"
        ><img
          :src="config.ciPath + '/assets/images/ryzom_logo.png'"
          style="height: 70px;"
          alt="Ryzom"
          title="Ryzom"
      /></a>
      <span v-else @click="clickHomeLink" class="cursor-pointer"
        ><img
          :src="config.ciPath + '/assets/images/ryzom_logo.png'"
          style="height: 70px;"
          alt="Ryzom"
          title="Ryzom"
      /></span>
    </div>
  </div>
</template>

<script>
import EventBus, { CLICK_HOME } from '../EventBus';

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    useNativeLinks: {
      type: Boolean,
      default: true,
    },
    useNuxtLinks: {
      type: Boolean,
      default: false,
    },
    homeLocation: {
      type: String,
      default: '/',
    },
  },
  methods: {
    clickHomeLink() {
      EventBus.$emit(CLICK_HOME);
    },
  },
};
</script>
