<template>
  <div class="font-larger">
    <template v-if="useNuxtLinks">
      <MenuBulletNuxt
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="pt-2 pb-2 d-flex align-items-center justify-content-end"
      ></MenuBulletNuxt>
    </template>
    <template v-else-if="useNativeLinks">
      <MenuBulletNative
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="pt-2 pb-2 d-flex align-items-center justify-content-end"
      ></MenuBulletNative>
    </template>
    <template v-else>
      <MenuBullet
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="pt-2 pb-2 d-flex align-items-center justify-content-end"
      ></MenuBullet>
    </template>
  </div>
</template>

<script>
import MenuBullet from '../menu/MenuBullet';
import MenuBulletNative from '../menu/MenuBulletNative';
import MenuBulletNuxt from '../menu/MenuBulletNuxt';

export default {
  components: {
    MenuBullet,
    MenuBulletNative,
    MenuBulletNuxt,
  },
  props: {
    bullets: {
      type: Array,
      default: () => [],
    },
    activeMenu: {
      type: String,
      default: null,
    },
    useNativeLinks: {
      type: Boolean,
      default: true,
    },
    useNuxtLinks: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
