<template>
  <div
    class="pt-2 pb-4 full-height d-flex align-items-center font-large text-light children-mr-2-not-last"
  >
    <font-awesome-icon
      :icon="['fas', 'globe']"
      class="mr-2"
    ></font-awesome-icon>
    <span
      v-if="languageEnabled('en')"
      :class="{ active: $i18n.locale === 'en' }"
      @click="changeLanguage('en')"
      class="cursor-pointer child"
      >en</span
    >
    <span
      v-if="languageEnabled('de')"
      :class="{ active: $i18n.locale === 'de' }"
      @click="changeLanguage('de')"
      class="cursor-pointer child"
      >de</span
    >
    <span
      v-if="languageEnabled('fr')"
      :class="{ active: $i18n.locale === 'fr' }"
      @click="changeLanguage('fr')"
      class="cursor-pointer child"
      >fr</span
    >
    <span
      v-if="languageEnabled('es')"
      :class="{ active: $i18n.locale === 'es' }"
      @click="changeLanguage('es')"
      class="cursor-pointer child"
      >es</span
    >
    <span
      v-if="languageEnabled('ru')"
      :class="{ active: $i18n.locale === 'ru' }"
      @click="changeLanguage('ru')"
      class="cursor-pointer child"
      >ru</span
    >
  </div>
</template>

<script>
import EventBus, { CHANGE_LANG } from '../../EventBus';
export default {
  props: {
    disabledLanguages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    languageEnabled(lang) {
      return !this.disabledLanguages.some((entry) => {
        return entry === lang;
      });
    },
    changeLanguage(languageCode) {
      EventBus.$emit(CHANGE_LANG, languageCode);
    },
  },
};
</script>
