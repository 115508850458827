<template>
  <span
    :class="'d-' + config.burgerBreakpoint + '-none'"
    @click="toggleBurger"
    class="pl-4 pr-4 full-height d-flex align-items-center font-medium cursor-pointer"
  >
    <font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
  </span>
</template>

<script>
import EventBus, { TOGGLE_BURGER } from '../EventBus';

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    toggleBurger() {
      EventBus.$emit(TOGGLE_BURGER);
    },
  },
};
</script>
