<template>
  <span :class="getClasses" @click="invokeLink(bullet.to)">
    {{ bullet.label }}
  </span>
</template>

<script>
import EventBus, { CLICK_MENU } from '../../EventBus';

export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    bullet: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getClasses() {
      let c = this.classes;
      if (this.active) {
        c = c + ' active';
      }
      return c;
    },
  },
  methods: {
    invokeLink(to) {
      EventBus.$emit(CLICK_MENU, to);
    },
  },
};
</script>
