<template>
  <html>
    <body>
      <ryzom-header
        v-bind="$props"
        :menu-bullets="parsedMenu"
        :user="parsedUser"
        :disabled-languages="parsedDisabledLanguages"
        v-on="$listeners"
        :home-location="'https://ryzom.com' + this.langSlug"
        @change-lang="onChangeLang"
        @change-identity="onChangeIdentity"
        @click-play-now="onClickPlayNow"
        @click-login="onClickLogin"
        @click-logout="onClickLogout"
        @click-home="onClickHome"
        @click-menu="onClickMenu"
      ></ryzom-header>
    </body>
  </html>
</template>

<script>
import VueI18n from 'vue-i18n';

import Vue from 'vue';

// import BootstrapVue from 'bootstrap-vue';

import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons/faGripHorizontal';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

import FlagIcon from 'vue-flag-icon';
import {
  CHANGE_IDENTITY,
  CHANGE_LANG,
  CLICK_HOME,
  CLICK_LOGIN,
  CLICK_LOGOUT,
  CLICK_MENU,
  CLICK_PLAY_NOW,
} from './components/EventBus';
import langEN from './lang/en-US';
import langDE from './lang/de-DE';
import langFR from './lang/fr-FR';
import langES from './lang/es-ES';
import langRU from './lang/ru-RU';
import RyzomHeader from './components/RyzomHeader';

Vue.use(FlagIcon);

// Vue.use(BootstrapVue);

Vue.use(VueI18n);

config.autoAddCss = false;
config.keepOriginalSource = false;

library.add(
  faBars,
  faGlobe,
  faSignOutAlt,
  faCog,
  faDownload,
  faGamepad,
  faUser,
  faCreditCard,
  faGripHorizontal,
  faComments,
  faChevronDown
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

export default {
  i18n: new VueI18n({
    locale: 'en',
    messages: {
      en: langEN,
      de: langDE,
      fr: langFR,
      es: langES,
      ru: langRU,
    },
  }),
  components: {
    RyzomHeader,
  },
  props: {
    lang: {
      type: String,
      default: 'en',
    },
    ciPath: {
      type: String,
      default: 'https://ci.ryzom.com',
    },
    enableLanguages: {
      type: Boolean,
      default: true,
    },
    enableLogin: {
      type: Boolean,
      default: true,
    },
    enablePlayNow: {
      type: Boolean,
      default: true,
    },
    enableWebigBar: {
      type: Boolean,
      default: true,
    },
    burgerBreakpoint: {
      type: String,
      default: 'lg',
    },
    menuBullets: {
      type: String,
      default: null,
    },
    activeMenu: {
      type: String,
      default: null,
    },
    user: {
      type: String,
      default: null,
    },
    closeClickOutsideElement: {
      type: Boolean,
      default: true,
    },
    disabledLanguages: {
      type: String,
      default: '[]',
    },
  },
  data() {
    return {
      // parsedMenu: JSON.parse(this.menuBullets),
      // parsedUser: JSON.parse(this.user),
      parsedDisabledLanguages: JSON.parse(this.disabledLanguages),
    };
  },
  computed: {
    parsedUser() {
      if (this.user) {
        return JSON.parse(this.user);
      }

      return null;
    },
    parsedMenu() {
      if (this.menuBullets) {
        return JSON.parse(this.menuBullets);
      }

      /* return [
        {
          key: 'home',
          to: 'https://ryzom.com' + this.langSlug,
          label: this.$t('menu.home'),
        },
        {
          key: 'webapps',
          to: 'https://app.ryzom.com?lang=' + this.lang,
          label: this.$t('menu.apps'),
        },
        {
          key: 'forum',
          to: 'https://app.ryzom.com/app_forum?lang=' + this.lang,
          label: this.$t('menu.forums'),
        },
        {
          key: 'wiki',
          to: 'https://' + this.lang + '.wiki.ryzom.com',
          label: this.$t('menu.wiki'),
        },
        {
          key: 'chat',
          to: 'https://chat.ryzom.com',
          label: this.$t('menu.chat'),
        },
      ]; */
      return [
        {
          key: 'universe',
          to: 'https://ryzom.com' + this.langSlug + '/universe',
          label: this.$t('menu.universe'),
        },
        {
          key: 'game',
          to: 'https://ryzom.com' + this.langSlug + '/game',
          label: this.$t('menu.gameInfo'),
        },
        {
          key: 'events',
          to: 'https://ryzom.com' + this.langSlug + '/events',
          label: this.$t('menu.events'),
        },
        {
          key: 'wiki',
          to: 'https://' + this.$i18n.locale + '.wiki.ryzom.com/',
          label: this.$t('menu.wiki'),
          target: '_blank',
        },
        {
          key: 'forums',
          to:
            'https://app.ryzom.com/app_forum/index.php?&lang=' +
            this.$i18n.locale,
          label: this.$t('menu.forums'),
        },
        {
          key: 'webapps',
          to: 'https://app.ryzom.com?lang=' + this.lang,
          label: this.$t('menu.apps'),
        },
        {
          key: 'chat',
          to: 'https://chat.ryzom.com/',
          label: this.$t('menu.chat'),
          target: '_blank',
        },
      ];
    },
    langSlug() {
      if (this.lang !== 'en') {
        return '/' + this.lang;
      }

      return '';
    },
  },
  created() {
    this.$i18n.locale = this.lang;
  },
  methods: {
    onChangeLang(data) {
      this.$emit(CHANGE_LANG, data);
    },
    onChangeIdentity(data) {
      this.$emit(CHANGE_IDENTITY, data);
    },
    onClickPlayNow(data) {
      this.$emit(CLICK_PLAY_NOW, data);
    },
    onClickLogin(data) {
      this.$emit(CLICK_LOGIN, data);
    },
    onClickLogout(data) {
      this.$emit(CLICK_LOGOUT, data);
    },
    onClickHome(data) {
      this.$emit(CLICK_HOME, data);
    },
    onClickMenu(data) {
      this.$emit(CLICK_MENU, data);
    },
  },
};
</script>

<style lang="scss">
@import 'scss/min';
@import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

@import 'scss/layout/header';
</style>
