<template>
  <div id="burger-menu">
    <div class="container extended">
      <div class="d-flex flex-column align-items-end pr-4 text-uppercase">
        <BurgerMenu
          :bullets="bullets"
          :active-menu="activeMenu"
          :use-native-links="useNativeLinks"
          :use-nuxt-links="useNuxtLinks"
        ></BurgerMenu>

        <BurgerWebigBar
          v-if="config.enableWebigBar"
          :bullets="webigBullets"
        ></BurgerWebigBar>

        <BurgerUser
          v-if="config.enableLogin"
          :user="user"
          class="mt-2 mb-2"
        ></BurgerUser>

        <BurgerLang
          v-if="config.enableLanguages"
          :disabled-languages="config.disabledLanguages"
        ></BurgerLang>
      </div>
    </div>
  </div>
</template>

<script>
import BurgerLang from './burger/BurgerLang';
import BurgerMenu from './burger/BurgerMenu';
import BurgerUser from './burger/BurgerUser';
import BurgerWebigBar from './burger/BurgerWebigBar';

export default {
  components: {
    BurgerWebigBar,
    BurgerLang,
    BurgerMenu,
    BurgerUser,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bullets: {
      type: Array,
      default: () => [],
    },
    activeMenu: {
      type: String,
      default: null,
    },
    webigBullets: {
      type: Array,
      default: () => [],
    },
    useNativeLinks: {
      type: Boolean,
      default: true,
    },
    useNuxtLinks: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {
        return { loggedIn: false, id: null, loginName: null, identities: [] };
      },
    },
  },
};
</script>
