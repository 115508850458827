<template>
  <header
    id="layout-header"
    ref="theHeader"
    :style="
      'border-image: url(' +
        ciPath +
        '/assets/images/ryzom_ui/skin_b.gif' +
        ') 8 repeat;'
    "
  >
    <nav class="full-height">
      <div class="container extended full-height">
        <div class="full-height d-flex">
          <HeaderLogo
            :config="getConfig"
            :use-native-links="useNativeLinks"
            :use-nuxt-links="useNuxtLinks"
            :home-location="homeLocation"
          ></HeaderLogo>

          <div class="p-1 full-width full-height d-flex flex-column">
            <div
              :class="'justify-content-' + burgerBreakpoint + '-between'"
              class="p-1 full-width full-height d-flex justify-content-end"
            >
              <HeaderMenu
                :config="getConfig"
                :bullets="menuBullets"
                :active-menu="activeMenu"
                :use-native-links="useNativeLinks"
                :use-nuxt-links="useNuxtLinks"
              ></HeaderMenu>

              <div class="d-flex full-height text-uppercase font-large">
                <HeaderBurgerToggle :config="getConfig"></HeaderBurgerToggle>

                <span
                  :class="'d-' + burgerBreakpoint + '-flex'"
                  class="d-none full-height"
                >
                  <HeaderUser
                    v-if="enableLogin"
                    :user="user"
                    ref="personalMenu"
                  ></HeaderUser>
                  <HeaderPlayNow v-if="enablePlayNow"></HeaderPlayNow>
                  <HeaderLangToggle v-if="enableLanguages"></HeaderLangToggle>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :style="
          'background-color: black;border-image: url(' +
            ciPath +
            '/assets/images/ryzom_ui/skin_b.gif' +
            ') 8 repeat;border-bottom: 8px solid transparent;'
        "
      >
        <HeaderWebigBar
          v-if="enableWebigBar"
          :bullets="webigBullets"
        ></HeaderWebigBar>

        <HeaderLang
          v-show="showLang"
          :config="getConfig"
          @change-lang="changeLanguage"
        ></HeaderLang>

        <HeaderBurger
          v-show="showBurger"
          :config="getConfig"
          :bullets="menuBullets"
          :webig-bullets="webigBullets"
          :active-menu="activeMenu"
          :use-native-links="useNativeLinks"
          :use-nuxt-links="useNuxtLinks"
          :user="user"
        ></HeaderBurger>
      </div>
    </nav>
  </header>
</template>

<script>
import HeaderLang from './header/HeaderLang';
import HeaderBurger from './header/HeaderBurger';
import HeaderMenu from './header/HeaderMenu';
import HeaderLogo from './header/HeaderLogo';
import HeaderBurgerToggle from './header/HeaderBurgerToggle';
import HeaderUser from './header/HeaderUser';
import HeaderPlayNow from './header/HeaderPlayNow';
import HeaderLangToggle from './header/HeaderLangToggle';
import HeaderWebigBar from './header/HeaderWebigBar';

import EventBus, {
  CHANGE_LANG,
  CHANGE_IDENTITY,
  CLICK_PLAY_NOW,
  CLICK_LOGIN,
  CLICK_LOGOUT,
  CLICK_HOME,
  CLICK_MENU,
  TOGGLE_BURGER,
  TOGGLE_LANG,
  HIDE_BURGER,
  HIDE_LANG,
} from './EventBus';

export default {
  components: {
    HeaderBurgerToggle,
    HeaderLang,
    HeaderBurger,
    HeaderLogo,
    HeaderMenu,
    HeaderUser,
    HeaderPlayNow,
    HeaderLangToggle,
    HeaderWebigBar,
  },
  props: {
    ciPath: {
      type: String,
      default: 'https://ci.ryzom.com',
    },
    enableLanguages: {
      type: Boolean,
      default: true,
    },
    enableLogin: {
      type: Boolean,
      default: true,
    },
    enablePlayNow: {
      type: Boolean,
      default: true,
    },
    enableWebigBar: {
      type: Boolean,
      default: true,
    },
    burgerBreakpoint: {
      type: String,
      default: 'lg',
    },
    menuBullets: {
      type: Array,
      default: () => [],
    },
    activeMenu: {
      type: String,
      default: null,
    },
    useNativeLinks: {
      type: Boolean,
      default: true,
    },
    useNuxtLinks: {
      type: Boolean,
      default: false,
    },
    homeLocation: {
      type: String,
      default: '/',
    },
    user: {
      type: Object,
      default: null,
    },
    closeClickOutsideElement: {
      type: Boolean,
      default: true,
    },
    disabledLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showBurger: false,
      showLang: false,
      webigBullets: [
        {
          title: 'Achievements',
          url: '/app_achievements/',
          image: '/app_achievements/favicon.png',
        },
        {
          title: 'Craft: Recipe-Book',
          url: '/app_craft/',
          image: '/app_craft/favicon.png',
        },
        {
          title: 'Dynamic Events',
          url: '/app_arcc/',
          image:
            '/app_arcc/data/app/Scripts//1/4/6/6/assets/points_atys_24.png',
        },
        {
          title: 'Forums',
          url: '/app_forum/',
          image: '/app_forum/favicon.png',
        },
        {
          title: 'Lore &amp; Chronicles',
          url: '/app_lore/',
          image: '/app_lore/favicon.png',
        },
        {
          title: 'Mail',
          url: '/app_mail/',
          image: '/app_mail/favicon.png',
        },
        {
          title: 'Notepad',
          url: '/app_notes/',
          image: '/app_notes/favicon.png',
        },
        {
          title: 'Paypal',
          url: '/app_paypal/',
          image: '/app_paypal/favicon.png',
        },
        {
          title: 'Profile',
          url: '/app_profile/',
          image: '/app_profile/favicon.png',
        },
        {
          title: 'Release Note',
          url: '/app_releasenotes/',
          image: '/app_releasenotes/favicon.png',
        },
        {
          title: 'Occupations',
          url: '/app_rpjobs/',
          image: '/app_rpjobs/favicon.png',
        },
        {
          title: 'RyzHome',
          url: '/app_ryzhome/',
          image: '/app_ryzhome/favicon.png',
        },
        {
          title: 'Ryzom API',
          url: '/app_ryzomapi/',
          image: '/app_ryzomapi/favicon.png',
        },
        {
          title: 'Support',
          url: '/app_support/',
          image: '/app_support/favicon.png',
        },
      ],
    };
  },
  computed: {
    getConfig() {
      return {
        ciPath: this.ciPath,
        enableLogin: this.enableLogin,
        enableLanguages: this.enableLanguages,
        enablePlayNow: this.enablePlayNow,
        enableWebigBar: this.enableWebigBar,
        burgerBreakpoint: this.burgerBreakpoint,
        disabledLanguages: this.disabledLanguages,
      };
    },
  },
  mounted() {
    if (this.closeClickOutsideElement === true) {
      window.addEventListener('click', (e) => {
        if (!document.getElementById('layout-header').contains(e.target)) {
          this.hideAll();
        }
      });
    }

    EventBus.$on(CHANGE_LANG, this.changeLanguage);
    EventBus.$on(CHANGE_IDENTITY, this.changeIdentity);

    EventBus.$on(CLICK_PLAY_NOW, this.clickPlayNowButton);
    EventBus.$on(CLICK_LOGIN, this.clickLoginButton);
    EventBus.$on(CLICK_LOGOUT, this.clickLogoutButton);
    EventBus.$on(CLICK_HOME, this.clickHomeButton);
    EventBus.$on(CLICK_MENU, this.clickMenuLink);

    EventBus.$on(TOGGLE_BURGER, this.toggleBurger);
    EventBus.$on(TOGGLE_LANG, this.toggleLang);

    EventBus.$on(HIDE_BURGER, this.hideBurger);
    EventBus.$on(HIDE_LANG, this.hideLang);
  },
  methods: {
    toggleBurger() {
      this.showBurger = !this.showBurger;
    },
    hideBurger() {
      this.showBurger = false;
    },
    toggleLang() {
      this.showLang = !this.showLang;
    },
    hideLang() {
      this.showLang = false;
    },
    hidePersonal() {
      if (this.$refs && this.$refs.personalMenu) {
        this.$refs.personalMenu.hide();
      }
    },
    changeLanguage(languageCode) {
      this.hideAll();
      this.$emit(CHANGE_LANG, languageCode);
    },
    changeIdentity(identity) {
      this.hideAll();
      this.$emit(CHANGE_IDENTITY, identity);
    },
    clickPlayNowButton() {
      this.hideAll();
      this.$emit(CLICK_PLAY_NOW);
    },
    clickHomeButton() {
      this.hideAll();
      this.$emit(CLICK_HOME);
    },
    clickLoginButton() {
      this.hideAll();
      this.$emit(CLICK_LOGIN);
    },
    clickLogoutButton() {
      this.hideAll();
      this.$emit(CLICK_LOGOUT);
    },
    clickMenuLink(to) {
      this.hideAll();
      this.$emit(CLICK_MENU, to);
    },
    hideAll() {
      this.hideLang();
      this.hideBurger();
      this.hidePersonal();
    },
  },
};
</script>
