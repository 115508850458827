<template>
  <span
    @click="toggleLang"
    class="pl-4 full-height d-flex align-items-center cursor-pointer"
  >
    <client-only
      ><flag :squared="false" :iso="currentFlag" class="ml-2 mr-2"></flag
    ></client-only>
    <font-awesome-icon :icon="['fas', 'chevron-down']"></font-awesome-icon>
  </span>
</template>

<script>
import EventBus, { TOGGLE_LANG } from '../EventBus';

export default {
  computed: {
    currentFlag() {
      const flagCode = {
        de: 'de',
        en: 'us',
        fr: 'fr',
        es: 'es',
        ru: 'ru',
      };

      return flagCode[this.$i18n.locale];
    },
  },
  methods: {
    toggleLang() {
      EventBus.$emit(TOGGLE_LANG);
    },
  },
};
</script>
