<template>
  <div id="lang-menu">
    <div class="container extended">
      <div
        class="d-flex flex-column align-items-end text-uppercase children-mb-2-last"
      >
        <span
          v-for="lang in availableLanguages"
          @click="changeLanguage(lang)"
          class="pt-2 pb-2 cursor-pointer child"
          >{{ langNames[lang] }}
          <client-only
            ><flag
              :squared="false"
              :iso="flagCode[lang]"
              class="ml-2"
            ></flag></client-only
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus, { CHANGE_LANG } from '../EventBus';

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      langNames: {
        de: 'deutsch',
        en: 'english',
        fr: 'français',
        es: 'español',
        ru: 'русский',
      },
      flagCode: {
        de: 'de',
        en: 'us',
        fr: 'fr',
        es: 'es',
        ru: 'ru',
      },
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    availableLanguages() {
      return ['en', 'de', 'fr', 'es', 'ru'].filter((entry) => {
        return entry !== this.currentLanguage && this.languageEnabled(entry);
      });
    },
  },
  methods: {
    languageEnabled(lang) {
      return !this.config.disabledLanguages.some((entry) => {
        return entry === lang;
      });
    },
    changeLanguage(languageCode) {
      EventBus.$emit(CHANGE_LANG, languageCode);
    },
  },
};
</script>
