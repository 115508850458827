<template>
  <div class="font-larger">
    <div
      v-if="!isLoggedIn"
      @click="clickLoginButton"
      class="pt-4 pb-2 full-height d-flex align-items-center cursor-pointer"
    >
      {{ $t('header.login') }}
    </div>
    <div v-else>
      <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
        {{ selectedIdentity }}
      </div>
      <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
        <!--<a href="http://app.ryzom.com" class="pr-2 text-nowrap">
          {{ $t('header.webig') }}
        </a>-->
        <a
          :href="
            'https://me.ryzom.com/?lang=' +
              $i18n.locale
          "
          class="pl-2 pr-2 text-nowrap"
        >
          {{ $t('header.billing') }}
        </a>
        <a @click="clickLogoutButton" href="#" class="pl-2 text-nowrap">
          {{ $t('header.logout') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus, { CLICK_LOGIN, CLICK_LOGOUT } from '../../EventBus';

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    selectedIdentity() {
      if (!this.user) {
        return '';
      }
      if (this.user.activeCharacterName) {
        return this.user.activeCharacterName;
      }
      return this.user.accountName;
    },
    isLoggedIn() {
      return !!this.user;
    },
  },
  methods: {
    clickLoginButton() {
      EventBus.$emit(CLICK_LOGIN);
    },
    clickLogoutButton() {
      EventBus.$emit(CLICK_LOGOUT);
    },
  },
};
</script>
