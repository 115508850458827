<template>
  <div>
    <template v-if="isInternal">
      <nuxt-link
        :class="getClasses"
        :to="bullet.to"
        :target="target"
        @click.native="invokeLink"
      >
        {{ bullet.label }}
      </nuxt-link>
    </template>
    <template v-else>
      <a
        :class="getClasses"
        :href="bullet.to"
        :target="target"
        @click="invokeLink"
      >
        {{ bullet.label }}
      </a>
    </template>
  </div>
</template>

<script>
import EventBus, { HIDE_BURGER } from '../../EventBus';
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    bullet: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getClasses() {
      let c = this.classes;
      if (this.active) {
        c = c + ' active';
      }
      return c;
    },
    target() {
      if (this.bullet.target) {
        return this.bullet.target;
      }

      return '_self';
    },
    isInternal() {
      return (
        this.bullet.to.substr(0, 5) !== 'https' &&
        this.bullet.to.substr(0, 4) !== 'http'
      );
    },
  },
  methods: {
    invokeLink() {
      EventBus.$emit(HIDE_BURGER);
    },
  },
};
</script>
