<template>
  <div
    :class="'d-' + config.burgerBreakpoint + '-flex'"
    class="h-100 text-uppercase d-none w-100 pl-4 pr-4 justify-content-between"
    style="font-size:1.2rem;max-width: 960px;"
  >
    <template v-if="useNuxtLinks">
      <MenuBulletNuxt
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="h-100 ml-1 mr-1 d-flex align-items-center cursor-pointer text-nowrap"
      ></MenuBulletNuxt>
    </template>
    <template v-else-if="useNativeLinks">
      <MenuBulletNative
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="h-100 ml-1 mr-1 d-flex align-items-center cursor-pointer text-nowrap"
      ></MenuBulletNative>
    </template>
    <template v-else>
      <MenuBullet
        v-for="bullet in bullets"
        :key="bullet.key"
        :bullet="bullet"
        :active="bullet.key === activeMenu"
        classes="h-100 ml-1 mr-1 d-flex align-items-center cursor-pointer text-nowrap"
      ></MenuBullet>
    </template>
  </div>
</template>

<script>
import MenuBullet from './menu/MenuBullet';
import MenuBulletNative from './menu/MenuBulletNative';
import MenuBulletNuxt from './menu/MenuBulletNuxt';

export default {
  components: {
    MenuBulletNuxt,
    MenuBulletNative,
    MenuBullet,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    bullets: {
      type: Array,
      default: () => [],
    },
    activeMenu: {
      type: String,
      default: null,
    },
    useNativeLinks: {
      type: Boolean,
      default: true,
    },
    useNuxtLinks: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
