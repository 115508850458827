<template>
  <div
    @click="clickPlayNowButton"
    class="h-100 d-flex align-items-center bg-dark hover-grayer cursor-pointer"
  >
    <div class="d-none d-xl-inline-block ml-4 mr-4 text-nowrap">
      {{ $t('header.playNow') }}
    </div>
    <div class="d-inline-block d-xl-none font-large ml-3 mr-3 text-nowrap">
      <font-awesome-icon :icon="['fas', 'download']"></font-awesome-icon> +
      <font-awesome-icon :icon="['fas', 'gamepad']"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
import EventBus, { CLICK_PLAY_NOW } from '../EventBus';

export default {
  methods: {
    clickPlayNowButton() {
      EventBus.$emit(CLICK_PLAY_NOW);
    },
  },
};
</script>
