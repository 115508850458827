<template>
  <div>
    <div
      v-if="!isLoggedIn"
      @click="clickLoginButton"
      class="pl-3 pl-xl-4 pr-3 pr-xl-4 h-100 d-flex align-items-center bg-dark hover-grayer cursor-pointer text-nowrap mr-1"
    >
      <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
      <span class="d-none d-xl-inline ml-2">{{ $t('header.login') }}</span>
    </div>
    <div v-else class="full-height d-flex align-items-center mr-2">
      <div class="d-flex justify-contend-between full-height">
        <div
          @click="toggleCharacterSelect"
          class="d-none d-xl-flex pl-4 pr-4 mr-1 full-height align-items-center bg-dark hover-grayer cursor-pointer"
          style="width: 150px;overflow: hidden;"
        >
          {{ selectedIdentity.name }}
        </div>
        <div
          @click="togglePersonalMenu"
          class="pl-1 pr-1 full-height d-flex align-items-center bg-dark hover-grayer cursor-pointer"
        >
          <font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon>
        </div>
      </div>
    </div>
    <div
      v-if="characterSelectToggle"
      class="full-height d-flex flex-column align-items-start"
      style="position: relative;width: 150px;"
    >
      <div
        v-for="c in availableChars"
        :key="c.cid"
        @click="selectIdentity(c)"
        class="pl-4 pr-4 pt-3 pb-3 d-flex align-items-center bg-gray-1 full-width hover-grayer cursor-pointer"
        style="overflow: hidden;"
      >
        <div>{{ c.name }}</div>
      </div>
    </div>
    <div
      v-if="personalMenuToggle"
      class="full-height d-flex flex-column align-items-end bg-dark"
      style="position: relative;width: 180px;"
    >
      <a
        :href="
          'https://me.ryzom.com/?lang=' + $i18n.locale
        "
        class="pl-4 pr-2 pt-3 pb-3 d-flex align-items-center justify-content-end bg-gray-1 full-width hover-grayer cursor-pointer"
        style="overflow: hidden;"
      >
        <div class="mr-2 text-nowrap">{{ $t('header.billing') }}</div>
        <span class="fa-fw">
          <font-awesome-icon :icon="['fas', 'credit-card']"></font-awesome-icon>
        </span>
      </a>
      <!--<a
        :href="'https://app.ryzom.com/app_forum/index.php?lang=' + $i18n.locale"
        class="pl-4 pr-2 pt-3 pb-3 d-flex align-items-center justify-content-end bg-gray-1 full-width hover-grayer cursor-pointer"
        style="overflow: hidden;"
      >
        <div class="mr-2 text-nowrap">{{ $t('header.forums') }}</div>
        <span class="fa-fw">
          <font-awesome-icon :icon="['fas', 'comments']"></font-awesome-icon>
        </span>
      </a>
      <a
        :href="'https://app.ryzom.com?lang=' + $i18n.locale"
        class="pl-4 pr-2 pt-3 pb-3 d-flex align-items-center justify-content-end bg-gray-1 full-width hover-grayer cursor-pointer"
        style="overflow: hidden;"
      >
        <div class="mr-2 text-nowrap">{{ $t('header.webig') }}</div>
        <span class="fa-fw">
          <font-awesome-icon
            :icon="['fas', 'grip-horizontal']"
          ></font-awesome-icon>
        </span>
      </a>-->
      <div
        @click="clickLogoutButton()"
        class="pl-4 pr-2 pt-3 pb-3 d-flex align-items-center justify-content-end bg-gray-1 full-width hover-grayer cursor-pointer"
        style="overflow: hidden;"
      >
        <div class="mr-2 text-nowrap">{{ $t('header.logout') }}</div>
        <span class="fa-fw">
          <font-awesome-icon
            :icon="['fas', 'sign-out-alt']"
          ></font-awesome-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus, { CLICK_LOGOUT, CLICK_LOGIN } from '../EventBus';

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      characterSelectToggle: false,
      personalMenuToggle: false,
    };
  },
  computed: {
    hasAlternativeIdentities() {
      if (!this.user) {
        return false;
      }
      return false; // this.user.characters.length > 1;
    },
    availableChars() {
      if (!this.user) {
        return [];
      }

      return this.user.characters.filter((entry) => {
        return entry.cid !== this.selectedIdentity.cid;
      });
    },
    selectedIdentity() {
      if (!this.user) {
        return {};
      }

      if (!this.user.activeCharacterName) {
        return { cid: null, name: this.user.accountName };
      }

      return this.user.characters.find((entry) => {
        return entry.name === this.user.activeCharacterName;
      });
    },
    isLoggedIn() {
      return !!this.user;
    },
  },
  methods: {
    hide() {
      this.personalMenuToggle = false;
      this.characterSelectToggle = false;
    },
    toggleCharacterSelect() {
      if (this.hasAlternativeIdentities) {
        this.personalMenuToggle = false;
        this.characterSelectToggle = !this.characterSelectToggle;
      } else {
        this.togglePersonalMenu();
      }
    },
    togglePersonalMenu() {
      this.characterSelectToggle = false;
      this.personalMenuToggle = !this.personalMenuToggle;
    },
    selectIdentity(c) {
      this.selectedIdentity = c;
      this.toggleCharacterSelect();
    },
    clickLoginButton() {
      EventBus.$emit(CLICK_LOGIN);
    },
    clickLogoutButton() {
      this.characterSelectToggle = false;
      this.personalMenuToggle = false;
      EventBus.$emit(CLICK_LOGOUT);
    },
  },
};
</script>
